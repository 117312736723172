import { Collection } from "~/repository/main/collection";
import { Document } from "~/repository/main/document";
import type * as firebaseTypes from "~/types/firebase";

export class OrderCollection extends Collection {
  constructor() {
    super("order_new");
  }

  public async getDocuments() {
    const querySnapshot = await this.ref.where("hospitalCode", "not-in", ["20200123"]).get();
    return querySnapshot.docs.map((doc) => {
      return { ...doc.data(), id: doc.id } as firebaseTypes.OrderDocumentData;
    });
  }

  public async getDocumentsByPatientId(patientId: string) {
    const querySnapshot = await this.ref.where("patientId", "==", patientId).get();
    return querySnapshot.docs.map((doc) => {
      return { ...doc.data(), id: doc.id } as firebaseTypes.OrderDocumentData;
    });
  }

  public async getDocumentsForAdmin(startDatetime: Date, endDatetime: Date) {
    const querySnapshot = await this.ref
      .where("addTimestamp", ">=", startDatetime)
      .where("addTimestamp", "<=", endDatetime)
      .get();
    return querySnapshot.docs.map((doc) => {
      return {
        ...doc.data(),
        id: doc.id,
      } as firebaseTypes.OrderDocumentData;
    });
  }

  public async addDocumentData(data: firebaseTypes.OrderDocumentDataAdd) {
    return await this._addDocumentData(data);
  }
}

export class OrderDocument extends Document {
  constructor(orderId: string) {
    super(`order_new/${orderId}`);
  }

  public async setDocumentData(data: firebaseTypes.OrderDocumentDataSet) {
    await this._setDocumentData(data);
  }
}
