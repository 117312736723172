import { Collection } from "~/repository/main/collection";
import { Document } from "~/repository/main/document";
import { type AddInfoInput, type EditInfoInput } from "~/types/video";

export class VideoCollection extends Collection {
  constructor(departmentId: string, organId: string, approachId: string, genreId: string) {
    super(`department/${departmentId}/organ/${organId}/approach/${approachId}/genre/${genreId}/video`);
  }

  public async addVideoData(item: Omit<AddInfoInput, "survey">) {
    return await this._addDocumentData(item);
  }

  public async getVideoData() {
    return await this._getDocumentData();
  }
}

export class VideoDocument extends Document {
  constructor(departmentId: string, organId: string, approachId: string, genreId: string, videoId: string) {
    super(`department/${departmentId}/organ/${organId}/approach/${approachId}/genre/${genreId}/video/${videoId}`);
  }

  public async updateDocumentdata(num: number) {
    return await this._updateDocumentData({
      displayPriority: num,
    });
  }

  public async deleteDocumentData() {
    await this._deleteDocumentData();
  }

  public async updateVideoData(input: Omit<EditInfoInput, "survey">) {
    return await this._updateDocumentData(input);
  }

  public async getVideoData() {
    return await this._getDocumentData();
  }

  public async updateIsUploadedFlag(input: { isUploaded: boolean }) {
    return await this._updateDocumentData(input);
  }
}
