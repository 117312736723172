import { Collection } from "~/repository/main/collection";
import { Document } from "~/repository/main/document";
import type * as firebaseTypes from "~/types/firebase";

export class PatientCollection extends Collection {
  constructor(hospitalId: string) {
    super(`hospital/${hospitalId}/patient`);
  }

  public async getDocumentDataByPatientId(patiendIds: string[]) {
    const qs = await this.ref.where("patientId", "in", patiendIds).get();
    return qs.docs;
  }

  public async getDocuments() {
    return await this._getDocumentData();
  }
}

export class PatientDocument extends Document {
  constructor(hospitalId: string, patientId: string) {
    super(`hospital/${hospitalId}/patient/${patientId}`);
  }

  public async getDocumentData() {
    return await this._getDocumentData();
  }

  public async setDocumentData(data: firebaseTypes.PatientDocumentDataSet) {
    await this._setDocumentData(data);
  }
}
