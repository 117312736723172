import { type Middleware, type NuxtAppOptions } from "@nuxt/types";

const engineer: Middleware = ({ store, redirect }: NuxtAppOptions) => {
  if (store?.state.user.user.role !== "engineer") {
    window.alert("このページはengineer権限でログインしている必要があります");
    return redirect("/");
  }
};

export default engineer;
