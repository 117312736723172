import { AdminCollection, AdminDocument } from "~/repository/components/admin";
import { AnswerCollection, AnswerDocument } from "~/repository/components/answer";
import { DoctorCollection } from "~/repository/components/doctor";
import { HospitalCollection, HospitalDepartmentCollection, HospitalDocument } from "~/repository/components/hospital";
import { NotificationMessageCollection, NotificationTargetCollection } from "~/repository/components/notification";
import { OrderCollection, OrderDocument } from "~/repository/components/order";
import { PatientCollection, PatientDocument } from "~/repository/components/patient";
import { SurveyCollection, SurveyDocument } from "~/repository/components/survey";
import { VideoCollection, VideoDocument } from "~/repository/components/video";

export const FirebaseCollections = {
  AdminCollection,
  AnswerCollection,
  DoctorCollection,
  HospitalCollection,
  HospitalDepartmentCollection,
  NotificationMessageCollection,
  NotificationTargetCollection,
  OrderCollection,
  PatientCollection,
  SurveyCollection,
  VideoCollection,
};

export const FirebaseDocuments = {
  AdminDocument,
  AnswerDocument,
  HospitalDocument,
  OrderDocument,
  PatientDocument,
  SurveyDocument,
  VideoDocument,
};
