import { Collection } from "~/repository/main/collection";
import { Document } from "~/repository/main/document";
import { type AnswerDocumentData } from "~/types/answer";

export class AnswerCollection extends Collection {
  constructor(orderId: string) {
    super(`order/${orderId}/answer`);
  }

  public async getAnswerData() {
    return await this._getDocumentData();
  }
}

export class AnswerDocument extends Document {
  constructor(orderId: string, answerId: string) {
    super(`order/${orderId}/answer/${answerId}`);
  }

  public async getAnswerDocument() {
    return (await this._getDocumentData()) as AnswerDocumentData;
  }
}
