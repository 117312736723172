import { Collection } from "~/repository/main/collection";
import { Document } from "~/repository/main/document";
import type * as firebaseTypes from "~/types/firebase";
import { type EditSurveyInput } from "~/types/survey";

export class SurveyCollection extends Collection {
  constructor(departmentId: string, organId: string, approachId: string, genreId: string, videoId: string) {
    super(
      `department/${departmentId}/organ/${organId}/approach/${approachId}/genre/${genreId}/video/${videoId}/survey`
    );
  }

  public async addSurveyData(item: firebaseTypes.addSurveyData) {
    return await this._addDocumentData(item);
  }

  public async getSurveyData() {
    return await this._getDocumentData();
  }
}

export class SurveyDocument extends Document {
  constructor(
    departmentId: string,
    organId: string,
    approachId: string,
    genreId: string,
    videoId: string,
    surveyId: string
  ) {
    super(
      `department/${departmentId}/organ/${organId}/approach/${approachId}/genre/${genreId}/video/${videoId}/survey/${surveyId}`
    );
  }

  public async getSurveyData() {
    return await this._getDocumentData();
  }

  public async updateSurveyData(input: EditSurveyInput) {
    return await this._updateDocumentData(input);
  }

  public async deleteSurveyData() {
    return await this._deleteDocumentData();
  }
}
